
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.favorite-service-toggle-button {
  $parent: &;

  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  transition: color $base-animation-time $base-animation-function;

  &__caption {
    font-size: 13px;
    line-height: 20px;

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__icon {
    position: relative;
    width: 24px;
    height: 24px;

    .favorite-icon-empty,
    .favorite-icon-filled,
    .favorite-icon-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity $base-animation-time $base-animation-function,
        color $base-animation-time $base-animation-function;
    }

    .favorite-icon-filled,
    .favorite-icon-loading {
      opacity: 0;
    }
  }

  &--is-active {
    .favorite-icon-empty {
      opacity: 0;
    }

    .favorite-icon-filled {
      opacity: 1;
      color: $color-yellow;
    }
  }

  &--is-loading {
    .favorite-icon-empty,
    .favorite-icon-filled {
      opacity: 0;
    }

    .favorite-icon-loading {
      opacity: 1;
    }
  }

  &--is-icon-only {
    #{$parent}__caption {
      display: none;
    }
  }

  &:hover {
    .favorite-icon-empty,
    .favorite-icon-filled {
      color: $color-yellow;
    }
  }
}
