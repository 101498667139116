
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.service-list-item {
  background: $color-white;
  box-shadow: $base-shadow;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;

  @include for-size(phone-portrait-down) {
    padding: 16px;
  }

  &__content {
    max-width: 584px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 20px;

    @include for-size(phone-portrait-down) {
      font-size: 13px;
    }
  }

  &__description {
    color: $color-black-op-50;
    margin-bottom: 16px;

    @include for-size(phone-portrait-down) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .esmp-tag {
      font-size: 12px;
      line-height: 16px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .esmp-button {
    color: $color-black;
  }
}
