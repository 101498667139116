
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$max-text-width: 460px;

.filler-block {
  $base-class: &;

  position: relative;
  background-color: $color-white;
  min-height: 168px;
  padding: 32px;
  border-radius: 16px;
  overflow: hidden;
  //max-width: 823px;

  &__text {
    position: relative;
    max-width: $max-text-width;
    z-index: 2;

    @include for-size(phone-portrait-down) {
      max-width: 180px;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }

  &__picture {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    @include for-size(phone-portrait-down) {
      width: 120px !important;
      right: -10px;
    }
  }

  &#{$base-class}--big {
    #{$base-class}__picture {
      right: 32px;

      @include for-size(tablet-portrait-down) {
        right: -20px;
      }

      @include for-size(phone-portrait-down) {
        right: -20px;
      }
    }
  }
}
